import React, {useEffect, useRef} from 'react';
import {Layer, Stage} from 'react-konva';
import ErrorBoundary from '../errorBoundary/ErrorBoundary';
import {objectTypes} from '../../helpers/canvasHelper';
import {getObjectDimension} from '../../helpers/positionHelper';
import {isFirefox} from '../../helpers/browserHelper';

export const PreviewObject = props => {
  const {
    previewRef,
    width: containerWidth = 300,
    height: containerHeight = 100,
  } = props;
  const {object, itemSize = {width: containerWidth, height: containerHeight}} =
    props;
  const previewObjRef = useRef(null);

  useEffect(() => {
    // Timeout fixed some crash on firefox
    if (isFirefox()) {
      setTimeout(() => {
        if (previewObjRef?.current) {
          previewObjRef.current.cache();
        }
      }, 150);
    }
  }, [object]);

  const oFill = object?.params?.fill;
  const oRadius = object?.params?.radius;
  const oStrokeWidth = object?.params?.strokeWidth || 0;
  const prevObject = {
    ...object,
    params: {...object?.params, scaleX: 1, scaleY: 1},
  };
  const oWidth = getObjectDimension(prevObject).width;
  const oHeight = getObjectDimension(prevObject).height;

  const previewScale =
    object?.className === objectTypes.text
      ? 1
      : itemSize.height / Math.max(oWidth, oHeight);

  const x = oRadius ? oWidth / 2 : oStrokeWidth / 2;
  const y = oRadius ? oHeight / 2 : oStrokeWidth / 2;

  const isRevertFill = ['white', '#fff', '#ffffff'].includes(oFill);

  const previewParams = {
    width: oWidth - oStrokeWidth,
    height: oHeight - oStrokeWidth,
    rotation: 0,
    offsetX: 0,
    offsetY: 0,
    scaleX: 1,
    scaleY: 1,
    x: oWidth >= oHeight ? x : x + (oHeight - oWidth) / 2,
    y: oHeight >= oWidth ? y : y + (oWidth - oHeight) / 2,
  };

  const textPreviewParams = {
    width: undefined,
    height: undefined,
    x: 0,
    y: 0,
    fontSize: containerHeight,
    align: undefined,
    verticalAlign: undefined,
    wrap: 'none',
    fill: isRevertFill ? 'black' : oFill,
  };

  return (
    <ErrorBoundary>
      <Stage
        ref={previewRef}
        width={itemSize?.width}
        height={itemSize?.height}
        scaleX={previewScale}
        scaleY={previewScale}>
        <Layer>
          <object.className
            ref={previewObjRef}
            key={object?.key}
            {...object?.params}
            {...previewParams}
            {...(object?.className === objectTypes.text
              ? textPreviewParams
              : {})}
          />
        </Layer>
      </Stage>
    </ErrorBoundary>
  );
};
