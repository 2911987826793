import * as S from './styled';

export const ItemBlock = ({title, content, children}) => {
  return (
    <S.ItemWr>
      {title && <h3>{title}</h3>}
      <S.ItemContent>{content || children}</S.ItemContent>
    </S.ItemWr>
  );
};
