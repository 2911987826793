import {createSlice} from '@reduxjs/toolkit';
import {unitKeys} from '../../helpers/unitHelper';

export const settingsSlice = createSlice({
  name: 'settings',
  initialState: {
    unit: unitKeys.cm,
  },
  reducers: {
    setUnit: (state, action) => {
      state.unit = action.payload;
    },
  },
});
export const {setUnit} = settingsSlice.actions;
