export const theme = {
  primaryColor: '#000',
  secondaryColor: '#4c8123',
  buttonText: '#ffffff',
  borderColor: '#d1d1d1',
  borderLightColor: '#e6e6e6',
  hoverColor: '#ededed',
  lightGrayColor: '#b5b5b5',
  errorColor: 'red',

  //Radius
  radiusButton: '5px',

  //font-family
  mainFont: 'Arial',

  //font-size
  exstraSmall: '10px',
  small: '12px',
  normal: '14px',
  semiMiddle: '16px',
  middle: '18px',
  semiBig: '20px',
  big: '22px',

  //@media
  screenSize: {
    xxs: `@media(max-width:479px)`,
    sm: `@media(max-width:640px)`,
    md: `@media(max-width:768px)`,
    lg: `@media(max-width:1024px)`,
    xl: `@media(max-width:1280px)`,
    xxl: `@media(max-width:1620px)`,
  },

  // Input label width
  labelWidth: '110px',
};
