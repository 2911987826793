import Konva from 'konva';
import {classNames} from './canvasHelper';

export const getObjectDimension = object => {
  let x = 0;
  let y = 0;
  let w = 0;
  let h = 0;
  const ref = new Konva[object?.className](object?.params);
  const {attrs} = ref;

  const {
    // scaleX = 1,
    // scaleY = 1,
    offsetX = 0,
    offsetY = 0,
    width = 0,
    height = 0,
    radius,
    strokeWidth = 0,
  } = attrs;

  const scaleX = Math.abs(attrs?.scaleX || 1);
  const scaleY = Math.abs(attrs?.scaleY || 1);

  if (
    object.className === classNames.text ||
    object.className === classNames.textPath ||
    object.className === classNames.group
  ) {
    x = ref?.getPosition().x - offsetX * scaleX;
    y = ref?.getPosition().y - offsetY * scaleY;
    w = ref?.getSize().width * scaleX;
    h = ref?.getSize().height * scaleY;
  }
  if (object.className === classNames.circle) {
    x =
      attrs?.x -
      (offsetX || 0) * scaleX -
      radius * scaleX -
      (strokeWidth ? (strokeWidth / 2) * scaleX : 0);
    y =
      attrs?.y -
      (offsetY || 0) * scaleY -
      radius * scaleY -
      (strokeWidth ? (strokeWidth / 2) * scaleY : 0);
    w = (radius * 2 + strokeWidth) * scaleX;
    h = (radius * 2 + strokeWidth) * scaleY;
  }
  if (
    object.className === classNames.rect ||
    object.className === classNames.image
  ) {
    x =
      attrs?.x -
      (strokeWidth ? strokeWidth / 2 : 0) * scaleX -
      offsetX * scaleX;
    y =
      attrs?.y -
      (strokeWidth ? strokeWidth / 2 : 0) * scaleY -
      offsetY * scaleY;
    w = (width + strokeWidth) * scaleX;
    h = (height + strokeWidth) * scaleY;
  }
  return {x, y, width: w, height: h};
};

export const applyCenterPositionForAddObject = (object, settings) => {
  object.params.x = settings.width / 2;
  object.params.y = settings.height / 2;
  if (object.params.x < 0) {
    object.params.x = 0;
  }
  if (object.params.y < 0) {
    object.params.y = 0;
  }
};

export const updateOffsets = (className, params) => {
  if (className !== classNames.circle) {
    const objectCopy = new Konva[className](params);
    const size = objectCopy.getSize();
    params.offsetX = size.width / 2;
    params.offsetY = size.height / 2;
  }
};

export const getTop = (o, settings) => {
  const {height: oHeight} = getObjectDimension(o);
  return Math.round(
    (o?.params?.y - oHeight / 2) / (settings?.height / 100 - oHeight / 100),
  );
};

export const getLeft = (o, settings) => {
  const {width: oWidth} = getObjectDimension(o);
  return Math.round(
    (o?.params?.x - oWidth / 2) / (settings?.width / 100 - oWidth / 100),
  );
};

export const getRoundValue = num => {
  return Math.round(num * 100) / 100;
};

export const getTopByPercentage = (o, value, settings) => {
  const {height: oHeight} = getObjectDimension(o);
  const objOffset = oHeight / 2 - (value * oHeight) / 100;
  const canvasOffset = (value * settings.height) / 100;
  return getRoundValue(canvasOffset + objOffset);
};

export const getLeftByPercentage = (o, value, settings) => {
  const {width: oWidth} = getObjectDimension(o);
  const objOffset = oWidth / 2 - (value * oWidth) / 100;
  const canvasOffset = (value * settings.width) / 100;
  return getRoundValue(canvasOffset + objOffset);
};

export const setTopByPercentage = (o, value, settings) => {
  o.params.y = getTopByPercentage(o, value, settings);
};

export const setLeftByPercentage = (o, value, settings) => {
  o.params.x = getLeftByPercentage(o, value, settings);
};
