import styled from 'styled-components';

type ButtonContainerType = {
  margin?: string;
  $background?: string;
  $disabled?: boolean;
  $border?: boolean;
  $borderColor?: string;
  $borderRadius?: string;
  height?: string;
  width?: string;
  $textColor?: string;
  $fontWeight?: number;
  padding?: string;
};

export const ButtonContainer = styled.div<ButtonContainerType>`
    position: relative;
    margin: ${props => (props.margin ? props.margin : '0px')};
    
    button,
    a {
        display: block;
        text-align: center;
        background-color: ${props =>
          props.$background && !props.$disabled
            ? props.$background
            : 'transparent'};
        opacity: ${props => (props.$disabled ? '0.65' : '1')};
        border: 1px solid
            ${props =>
              props.$border
                ? props.$borderColor || props.theme.borderColor
                : 'transparent'};
        border-radius: ${props =>
          props.$borderRadius ? props.$borderRadius : '25px'};
        height: ${props => (props.height ? `${props.height}px` : 'auto')};
        width: ${props => (props.width ? `${props.width}px` : '100%')};
        color: ${props =>
          props.$textColor ? props.$textColor : props.theme.whiteColor};
        font-size: ${props => props.theme.primaryFontSize};
        font-weight: ${props =>
          props.$fontWeight ? props.$fontWeight : '500'};
        cursor: ${props => (props.$disabled ? 'not-allowed' : 'pointer')};
        padding: ${props => (props.padding ? props.padding : '5px')};
        text-decoration: none;
        //&:hover {
        //    opacity: 0.5;
        //    //transition: 2s ease-out;
        //}
    }
    svg {
        position: absolute;
        margin: auto 0;
        top: 0;
        bottom: 0;
        right:50%;
        width:${props => (props.width ? `${props.width}px` : 'auto')}
        color: ${props =>
          props.$textColor ? props.$textColor : props.theme.whiteColor};
        transform:translateX(50%);
    }
`;
