import React from 'react';
import Dropzone from 'react-dropzone';
import classNames from 'classnames';
import * as S from './styled';

export const DropZoneWrapper = props => {
  const {onDrop, onBlur, disabled, multiple, className, children} = props;

  return (
    <Dropzone
      onDrop={onDrop}
      onBlur={onBlur}
      disabled={disabled}
      multiple={multiple}>
      {({getRootProps, getInputProps, isDragActive}) => {
        return (
          <section className="container">
            <S.Container
              {...getRootProps({
                className: classNames(
                  `dropzone`,
                  className,
                  disabled && 'disabled',
                  {
                    'dropzone--isActive': isDragActive,
                  },
                ),
              })}>
              <input {...getInputProps()} />
              {children}
            </S.Container>
          </section>
        );
      }}
    </Dropzone>
  );
};
