import React from 'react';
import {faChevronDown} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import * as S from './styled';

type ButtonWrapperType = {
  background?: string;
  textColor?: string;
  borderRadius?: string;
  width?: string;
  height?: string;
  padding?: string;
  disabled?: boolean;
  border?: boolean;
  children?: any;
  onClick: Function;
  entity?: Function;
  borderColor?: string;
  iconDropdown?: boolean;
  margin?: string;
  fontWeight?: number;
};

export const ButtonWrapper: React.FC<ButtonWrapperType> = ({
  background,
  textColor,
  borderRadius,
  width,
  height,
  padding,
  disabled,
  border,
  children,
  onClick,
  entity,
  borderColor,
  iconDropdown,
  margin,
  fontWeight,
}) => {
  const boundOnClick = () => onClick(entity);

  return (
    <S.ButtonContainer
      $borderColor={borderColor}
      $background={background}
      width={width}
      $border={border}
      $textColor={textColor}
      height={height}
      padding={padding}
      $borderRadius={borderRadius}
      $disabled={disabled}
      margin={margin}
      $fontWeight={fontWeight}>
      <button type="button" disabled={disabled} onClick={boundOnClick}>
        {children}
      </button>
      {iconDropdown && <FontAwesomeIcon icon={faChevronDown} />}
    </S.ButtonContainer>
  );
};
