import React from 'react';
import * as S from './styled';

export const SelectorWithIcons = props => {
  const {data, value, onChange, marginsInside = 0} = props;

  const listContainer = data.map(i => (
    <S.Item
      key={i?.value}
      $isSelected={i?.value === value}
      $marginsInside={marginsInside}
      onClick={() => onChange(i?.value !== value ? i?.value : undefined)}>
      {i?.icon}
    </S.Item>
  ));

  return <S.List>{listContainer}</S.List>;
};
