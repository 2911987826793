import styled from 'styled-components';

export const ColorPicker = styled.div`
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid #e6e6e6;
  width: 40px;
  min-width: 40px;
  height: 40px;
  background-color: ${props => props.$color};
`;

export const ColorPickerWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  column-gap: 0.625rem;
  width: 100%;
  height: 40px;
  input {
    width: 100%;
  }
`;

export const ColorPickerModal = styled.div`
  position: absolute;
  top: 100%;
  z-index: 3;
`;
