import * as S from './styled';
import {getValueByUnit} from '../../helpers/unitHelper';

export const InputField = ({
  label,
  typeTitle,
  inputRef,
  inputUnit,
  outputUnit,
  dpi,
  ...props
}) => {
  const changedProps =
    outputUnit === '%'
      ? {
          ...(props?.value !== undefined
            ? {value: Math.round(Number(props?.value) * 100) || 100}
            : {}),
          ...(props?.defaultValue !== undefined
            ? {
                defaultValue:
                  Math.round(Number(props?.defaultValue) * 100) || 100,
              }
            : {}),
          ...(props?.onChange
            ? {
                onChange: e =>
                  // @ts-ignore
                  props.onChange({
                    ...e,
                    target: {
                      ...e?.target,
                      value: Number(e?.target?.value / 100),
                    },
                  }),
              }
            : {}),
        }
      : inputUnit && outputUnit
      ? {
          ...(props?.value !== undefined
            ? {value: getValueByUnit(props?.value, inputUnit, outputUnit, dpi)}
            : {}),
          ...(props?.defaultValue !== undefined
            ? {
                defaultValue: getValueByUnit(
                  props?.defaultValue,
                  inputUnit,
                  outputUnit,
                  dpi,
                ),
              }
            : {}),
          ...(props?.onChange
            ? {
                onChange: e =>
                  // @ts-ignore
                  props.onChange({
                    ...e,
                    target: {
                      ...e?.target,
                      value: getValueByUnit(
                        Number(e.target?.value),
                        outputUnit,
                        inputUnit,
                        dpi,
                      ),
                    },
                  }),
              }
            : {}),
        }
      : {...props};

  return (
    <S.WrapperInput>
      {label && <S.LabelBlock>{label}</S.LabelBlock>}
      <S.InputBlock $withLabel={Boolean(label)}>
        {typeTitle ? <S.TypeBlock>{typeTitle}</S.TypeBlock> : null}
        <input ref={inputRef} type={'number'} {...props} {...changedProps} />
      </S.InputBlock>
    </S.WrapperInput>
  );
};
