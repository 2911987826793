import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 40px;
  align-items: center;
`;

export const Icon = styled.img`
  width: 40px;
  height: 40px;
  cursor: pointer;
  border: ${props => (props.$isSelected ? '2.4px solid #000' : 'none')};
`;
