import styled from 'styled-components';

export const RootContainer = styled.div``;
export const RadioWrapper = styled.div`
  background-color: ${props => props.background || 'transparent'};
  border: ${props => (props.border ? '1px solid #e1e3e5' : 'none')};
  width: ${props => props.width || '100%'};
  form {
    display: flex;
    flex-wrap: wrap;
    font-weight: ${props => props.theme.fontWeightSemiBold};
    color: #1e1e1e;
    flex-direction: column;
    span {
      padding-left: 10px;
    }
    label {
      display: inline-block;
      margin-bottom: 0.5rem;
    }
  }
`;

export const WrapperInput = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 40px;
  align-items: center;
`;

export const LabelBlock = styled.h4`
  width: ${props => props.theme.labelWidth};
    min-width: ${props => props.theme.labelWidth};
  font-size: ${props => props.theme.normal};
  font-weight: bold;
  color: #000;
  font-family: ${props => props.theme.mainFont};
`;
