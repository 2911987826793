import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import * as S from './styled';

export class TextAreaField extends PureComponent {
  static propTypes = {
    placeholder: PropTypes.string,
    onChangeHandler: PropTypes.func,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    value: PropTypes.string,
    rows: PropTypes.number,
  };

  render() {
    const {
      style,
      placeholder,
      className,
      disabled,
      value,
      onChangeHandler,
      rows,
    } = this.props;

    return (
      <S.Root>
        <div className="textarea-field">
          <textarea
            style={style}
            placeholder={placeholder}
            className={className}
            disabled={disabled}
            value={value}
            onChange={onChangeHandler}
            rows={rows}
          />
        </div>
      </S.Root>
    );
  }
}
