export const syncRefAttrsWithParams = (ref, params) => {
  const keys = Object.keys(params);
  if (ref) {
    keys.forEach(key => {
      if (ref?.getAttr && params?.[key] !== ref?.getAttr(key)) {
        ref.setAttr(key, params[key]);
      }
    });
  }
};
