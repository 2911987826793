export const getImageUrlWithoutAdditionalParams = url => {
  if (url) {
    const timestampIndex = url.indexOf(`?`);
    if (timestampIndex !== -1) {
      return url.substring(0, timestampIndex);
    }
    return url;
  }
};

export const checkAndFixTemplateSize = (
  objects,
  settings,
  isCheck,
  isAddToOrderRow,
) =>
  new Promise(resolve => {
    const bgParams = objects?.[0]?.params;
    const image = new Image();
    const imageSrc = isAddToOrderRow
      ? bgParams?.imageSrc || bgParams?.fillPatternImageSrc
      : getImageUrlWithoutAdditionalParams(
          bgParams?.image?.src || bgParams?.fillPatternImage?.src,
        );
    image.src = imageSrc;
    if (isCheck && objects?.length === 1 && imageSrc) {
      image.onload = () => {
        const scaleValue = settings?.width / image.width;
        if (
          (image.width > settings?.width || image.height > settings?.height) &&
          Math.min(settings?.width, settings?.height) < 2000 &&
          image.width + image.height < 9449 &&
          scaleValue < 0.95
        ) {
          const newDpi = Math.round((settings?.dpi || 96) / scaleValue);
          console.log('Fix template size DPI:', newDpi);
          settings.dpi = newDpi;
          settings.width = image.width;
          settings.height = image.height;
          bgParams.width = image.width;
          bgParams.height = image.height;
          if (bgParams?.fillPatternImage) {
            if (bgParams?.fillPatternScaleX) {
              bgParams.fillPatternScaleX =
                bgParams.fillPatternScaleX / scaleValue;
            }
            if (bgParams?.fillPatternScaleY) {
              bgParams.fillPatternScaleY =
                bgParams.fillPatternScaleY / scaleValue;
            }
            if (bgParams?.fillPatternOffsetX) {
              bgParams.fillPatternOffsetX =
                bgParams.fillPatternOffsetX * scaleValue;
            }
            if (bgParams?.fillPatternOffsetY) {
              bgParams.fillPatternOffsetY =
                bgParams.fillPatternOffsetY * scaleValue;
            }
          }
        }
        resolve({objects, settings});
      };
    } else {
      resolve({objects, settings});
    }
  });
