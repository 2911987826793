import React, {useEffect} from 'react';
import {ThemeProvider} from 'styled-components';
import {Layout} from './layout/layout';
import {fixInputsWithNumbers} from './helpers/textInputsHelper';
import './App.css';
import {theme} from './layout/theme';
import './helpers/i18n';

const App = () => {
  const queryParameters = new URLSearchParams(location.search);
  const pid = queryParameters.get('product_id');
  const vid = queryParameters.get('product_variant_id');
  const applicationKey = queryParameters.get('application_key');
  const cartKey = queryParameters.get('cart_item_key');
  const apiConfig = {headers: {'Application-Key': applicationKey}};

  useEffect(() => {
    fixInputsWithNumbers();
  }, []);

  // Text URL:
  // ?product_id=410&product_variant_id=411&application_key=4c4626f3-a31e-46cd-9912-698af8ab9e78&cart_item_key=35a793feb4fd04c8f37951ff1a9c0bc7

  return (
    <ThemeProvider theme={theme}>
      <Layout pid={pid} vid={vid} cartKey={cartKey} apiConfig={apiConfig} />
    </ThemeProvider>
  );
};

export default App;
