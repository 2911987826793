import React from 'react';
import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faUpRightAndDownLeftFromCenter,
  faHand,
  faEdit,
  faRotate,
} from '@fortawesome/free-solid-svg-icons';
import {theme} from '../theme';
import {ButtonWrapper, QuantityCounter} from '../../components';
import zoomOutIcon from '../../assets/icons/png/zoom-in-icon.png';
import zoomInIcon from '../../assets/icons/png/zoom-out-icon.png';
import * as S from './styled';

export const Footer = props => {
  const {
    size,
    setSize,
    onFitToPageClicked,
    onAddToCartClick,
    onPreviewClick,
    editMode,
    setEditMode,
    canvasRotation,
    setCanvasRotation,
    loading,
    sideBarWidth,
    isVertical,
    windowWidth,
  } = props;
  const {t} = useTranslation(['footer']);

  const editModesContainer = (
    <div>
      <FontAwesomeIcon
        icon={faEdit}
        onClick={() => setEditMode(true)}
        color={editMode ? theme.primaryColor : 'lightGray'}
      />
      <FontAwesomeIcon
        icon={faHand}
        onClick={() => setEditMode(false)}
        color={!editMode ? theme.primaryColor : 'lightGray'}
      />
    </div>
  );

  const rotateButton = (
    <S.RotateIconWrapper
      onClick={() =>
        setCanvasRotation(canvasRotation < 270 ? canvasRotation + 90 : 0)
      }>
      <FontAwesomeIcon
        className={'rotate-icon'}
        icon={faRotate}
        color={theme.primaryColor}
      />
    </S.RotateIconWrapper>
  );

  const zoomInButton = (
    <S.ZoomIconWrapper
      onClick={() => {
        if (size < 100) {
          setSize(size + 1);
        }
      }}>
      <S.ZoomIcon src={zoomInIcon} alt={'zoomInIcon'} />
    </S.ZoomIconWrapper>
  );

  const zoomOutButton = (
    <S.ZoomIconWrapper
      onClick={() => {
        if (size > 0) {
          setSize(size - 1);
        }
      }}>
      <S.ZoomIcon src={zoomOutIcon} alt={'zoomOutIcon'} />
    </S.ZoomIconWrapper>
  );

  const resizeButton = (
    <S.ResizeIconWrapper>
      <FontAwesomeIcon
        className={'resize-icon'}
        icon={faUpRightAndDownLeftFromCenter}
        onClick={onFitToPageClicked}
      />
    </S.ResizeIconWrapper>
  );

  const previewButton = (
    <S.PreviewButton>
      <button onClick={onPreviewClick}>{t('Preview')}</button>
    </S.PreviewButton>
  );

  const addToCartButton = (
    <S.AddToCart disabled={loading}>
      <button disabled={loading} onClick={onAddToCartClick}>
        {t('Add to cart')}
      </button>
    </S.AddToCart>
  );

  const zoomButtonsContainer = (
    <S.CounterWr>
      {zoomInButton}
      {zoomOutButton}
      {resizeButton}
    </S.CounterWr>
  );

  const addAndPrevButtonsContainer = (
    <S.AddAndPrevButtons $isVertical={isVertical}>
      {previewButton}
      {addToCartButton}
    </S.AddAndPrevButtons>
  );

  const iconsContainer = (
    <S.IconsRow>
      <S.EditModeWr>{rotateButton}</S.EditModeWr>
      {zoomButtonsContainer}
      {/*<div style={{width: 30}} />*/}
    </S.IconsRow>
  );

  return (
    <S.Footer $isVertical={isVertical}>
      <S.CanvasButtonsArea $isVertical={isVertical}>
        {iconsContainer}
        {addAndPrevButtonsContainer}
      </S.CanvasButtonsArea>
    </S.Footer>
  );
};
