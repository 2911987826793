import styled from "styled-components";

export const ArrowsWr = styled.div`
  display: flex;
  flex-direction: column;
  h4 {
    font-size: ${props => props.theme.normal};
    font-weight: 400;
    color: #000;
      font-family: ${props => props.theme.mainFont};
  }
`;

export const ArrowsBlock = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  gap: 5px;
`;

export const BtnWr = styled.div`
  cursor: pointer;
  border: 1px solid #d1d1d1;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  padding: 5px;

  &:hover {
    background: #efefef;
  }

  img {
    width: 17px;
  }
`;
