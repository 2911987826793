import React from 'react';
import PropTypes from 'prop-types';
import * as S from './styled';

export const ModalWrapper = ({
  popupRef,
  title,
  isNotFixed,
  isScrolled = true,
  children,
  buttonsContainer,
  zIndex,
  withCross,
  onCancelClick,
}) => {
  const wrapperStyle = {
    position: !isNotFixed ? 'fixed' : 'relative',
    top: !isNotFixed ? '0' : 'auto',
    left: !isNotFixed ? '0' : 'auto',
    bottom: !isNotFixed ? '0' : 'auto',
    right: !isNotFixed ? '0' : 'auto',
    width: !isNotFixed ? '100%' : 'auto',
    height: !isNotFixed ? '100%' : '500px',
    zIndex,
  };

  const titleHeader = title && (
    <div className="dialog-title-container">
      <div className="dialog-titleText">{title}</div>
      {withCross && <span className="dialog-cross" onClick={onCancelClick} />}
    </div>
  );

  return (
    <S.Root>
      <div className="dialog-wr" style={wrapperStyle}>
        <div className="dialog-bg" />
        <div ref={popupRef} className="dialog-container">
          <div className="dialog-window">
            {titleHeader}
            <div
              className={`content${
                isScrolled ? ' dialog-scrollableContent' : ''
              }`}>
              {children}
            </div>
            {buttonsContainer ? buttonsContainer : null }
          </div>
        </div>
      </div>
    </S.Root>
  );
};

ModalWrapper.propTypes = {
  title: PropTypes.string,
  isScrolled: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  onCancelClick: PropTypes.func,
  withCross: PropTypes.bool,
  isNotFixed: PropTypes.bool,
  zIndex: PropTypes.number,
  buttonsContainer: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ])
};
