import {useRef, useState} from 'react';
import {SketchPicker} from 'react-color';
import {useOutsideClick} from '../../hooks';
import {InputField} from '../inputField';
import * as S from './styled';

export const ColorPicker = ({color, onChange = () => {}, withInput}) => {
  const [showColor, setShowColor] = useState(false);

  const colorRef = useRef(null);
  useOutsideClick(colorRef, setShowColor);

  return (
    <S.ColorPickerWrapper>
      <S.ColorPicker
        onClick={() => setShowColor(true)}
        $color={color || 'white'}
      />
      {showColor && (
        <S.ColorPickerModal ref={colorRef}>
          <SketchPicker
            color={color}
            onChangeComplete={(value, event) => {
              onChange(value.hex);
              if (event?.type === 'click') {
                setShowColor(false);
              }
            }}
          />
        </S.ColorPickerModal>
      )}
      {withInput && (
        <InputField
          type="text"
          value={color}
          onChange={e => onChange(e.target.value)}
        />
      )}
    </S.ColorPickerWrapper>
  );
};
