import styled from 'styled-components';

export const Notification = styled.div`
  z-index: 98;
  padding: 0.625rem 1.875rem 0.625rem 4.688rem;
  background: #ffffff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
  border-radius: 2.625rem 0 0 2.625rem;
  font-size: 1rem;
  color: #6f6f6f;
  height: auto;
  width: 100%;
  max-width: 34.375rem;
  svg {
    position: absolute;
    top: 50%;
    left: 0.938rem;
    width: 1.375rem;
    transform: translateY(-50%);
    font-size: 1.875rem;
    line-height: 1;
  }
`;
