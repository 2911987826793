import RepeatIcon from '../../assets/icons/repeat/Align_icon_1.svg';
import RepeatWaveVerticalIcon from '../../assets/icons/repeat/Align_icon_2.svg';
import RepeatWaveIcon from '../../assets/icons/repeat/Align_icon_3.svg';
export const repeatIconsData = [
  {
    value: 'repeat',
    icon: <img src={RepeatIcon} alt={'icon'} />,
  },
  {
    value: 'repeat-wave',
    icon: <img src={RepeatWaveIcon} alt={'icon'} />,
  },
  {
    value: 'repeat-wave-vertical',
    icon: <img src={RepeatWaveVerticalIcon} alt={'icon'} />,
  },
];
