import styled from 'styled-components';

export const Dropzone = styled.div``;

export const Container = styled.div`
  // flex: 1;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  // padding: 20px;
  // border-width: 2px;
  // border-radius: 2px;
  // border-color: ${props => props.theme.primaryColor};;
  // border-style: dashed;
  // background-color: #fafafa;
  // color: #bdbdbd;
  // outline: none;
  // transition: border .24s ease-in-out;
`;
