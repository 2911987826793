import React from 'react';
import boldIcon from '../../assets/icons/style/Bold.svg';
import italicIcon from '../../assets/icons/style/Italic.svg';
import * as S from './styled';

const styleData = [
  {
    id: 'bold',
    icon: boldIcon,
  },
  {
    id: 'italic',
    icon: italicIcon,
  },
];

export const FontStyleSelector = props => {
  const {value, onChange} = props;

  const isSelected = id => {
    if (id === 'bold' && (value === 'bold' || value === 'italic bold')) {
      return true;
    } else if (
      id === 'italic' &&
      (value === 'italic' || value === 'italic bold')
    ) {
      return true;
    }
    return false;
  };

  const onChangeSelected = id => {
    if (id === 'bold') {
      if (value === 'bold') {
        onChange('normal');
      } else if (value === 'italic') {
        onChange('italic bold');
      } else if (value === 'italic bold') {
        onChange('italic');
      } else {
        onChange('bold');
      }
    }
    if (id === 'italic') {
      if (value === 'italic') {
        onChange('normal');
      } else if (value === 'bold') {
        onChange('italic bold');
      } else if (value === 'italic bold') {
        onChange('bold');
      } else {
        onChange('italic');
      }
    }
  };

  return (
    <S.Wrapper>
      {styleData.map(i => (
        <S.Icon
          key={i?.id}
          src={i?.icon}
          alt={'icon'}
          $isSelected={isSelected(i?.id)}
          onClick={() => onChangeSelected(i?.id)}
        />
      ))}
    </S.Wrapper>
  );
};
