import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faAlignCenter,
  faAlignRight,
  faAlignLeft,
  faArrowUp,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
} from '@fortawesome/free-solid-svg-icons';

export const alignVariants = {
  top: 'top',
  center: 'center',
  bottom: 'bottom',
  left: 'left',
  middle: 'middle',
  right: 'right',
};

export const positionVariants = {
  top: 'top',
  center: 'center',
  bottom: 'bottom',
  left: 'left',
  middle: 'middle',
  right: 'right',
};

export const moveVariants = {
  left: 'left',
  right: 'right',
  up: 'up',
  down: 'down',
};

export const horizontalAlignMenu = [
  {
    id: alignVariants.left,
    icon: <FontAwesomeIcon icon={faAlignLeft} />,
  },
  {
    id: alignVariants.center,
    icon: <FontAwesomeIcon icon={faAlignCenter} />,
  },
  {
    id: alignVariants.right,
    icon: <FontAwesomeIcon icon={faAlignRight} />,
  },
  // {
  //   label: t('Align top'),
  //   icon: <FontAwesomeIcon icon={faArrowUp} />,
  //   action: () => action(alignVariants.top),
  // },
  // {
  //   label: t('Align middle'),
  //   icon: <FontAwesomeIcon icon={faAlignCenter} />,
  //   action: () => action(alignVariants.middle),
  // },
  // {
  //   label: t('Align bottom'),
  //   icon: <FontAwesomeIcon icon={faArrowDown} />,
  //   action: () => action(alignVariants.bottom),
  // },
];

export const verticalAlignMenu = [
  {
    id: alignVariants.top,
    icon: <FontAwesomeIcon icon={faArrowUp} />,
  },
  {
    id: alignVariants.middle,
    icon: <FontAwesomeIcon icon={faAlignCenter} />,
  },
  {
    id: alignVariants.bottom,
    icon: <FontAwesomeIcon icon={faArrowDown} />,
  },
];

export const positionMenu = [
  {
    id: positionVariants.left,
    icon: <FontAwesomeIcon icon={faArrowLeft} />,
  },
  {
    id: positionVariants.center,
    icon: <FontAwesomeIcon icon={faAlignCenter} />,
  },
  {
    id: positionVariants.right,
    icon: <FontAwesomeIcon icon={faArrowRight} />,
  },
  {
    id: positionVariants.top,
    icon: <FontAwesomeIcon icon={faArrowUp} />,
  },
  {
    id: positionVariants.middle,
    icon: <FontAwesomeIcon icon={faAlignCenter} />,
  },
  {
    id: positionVariants.bottom,
    icon: <FontAwesomeIcon icon={faArrowDown} />,
  },
];

export const moveMenu = [
  {
    id: moveVariants.left,
    icon: <FontAwesomeIcon icon={faArrowLeft} />,
  },
  {
    id: moveVariants.right,
    icon: <FontAwesomeIcon icon={faArrowRight} />,
  },
  {
    id: moveVariants.up,
    icon: <FontAwesomeIcon icon={faArrowUp} />,
  },
  {
    id: moveVariants.down,
    icon: <FontAwesomeIcon icon={faArrowDown} />,
  },
];
