export const pixelsToCm = (pixels, dpi) => {
  return (pixels * 2.54) / dpi;
};

export const cmToPixels = (cm, dpi) => {
  return (cm * dpi) / 2.54;
};

export const pixelsToInches = (pixels, dpi) => {
  return pixels / dpi;
};

export const inchesToPixels = (inch, dpi) => {
  return inch * dpi;
};

export const unitKeys = {
  pixels: 'pixels',
  mm: 'mm',
  cm: 'cm',
  inch: 'inch',
};

export const unitLabels = {
  cm: 'CM',
  inch: 'Inch',
};

export const sizeUnits = [unitKeys.inch, unitKeys.cm];

export const getRoundValue = num => {
  return Math.round(num * 100) / 100;
};

export const getValueByUnit = (value, inputUnit, outputUnit, dpi = 96) => {
  if (inputUnit === unitKeys.pixels && outputUnit === unitKeys.cm) {
    return getRoundValue(pixelsToCm(value, dpi));
  }
  if (inputUnit === unitKeys.cm && outputUnit === unitKeys.pixels) {
    return getRoundValue(cmToPixels(value, dpi));
  }
  if (inputUnit === unitKeys.pixels && outputUnit === unitKeys.inch) {
    return getRoundValue(pixelsToInches(value, dpi));
  }
  if (inputUnit === unitKeys.inch && outputUnit === unitKeys.pixels) {
    return getRoundValue(inchesToPixels(value, dpi));
  }
  return getRoundValue(value);
};
