import * as S from './styled';

import ArrowPrev from '../../assets/icons/rotate-ccw.svg';
import ArrowNext from '../../assets/icons/rotate-cw.svg';

export const ArrowsBlock = ({label, onLeftClick, onRightClick}) => {
  return (
    <S.ArrowsBlock>
      <S.BtnWr onClick={onLeftClick}>
        <img src={ArrowPrev} alt={'arrow-prev'} />
      </S.BtnWr>
      <S.BtnWr onClick={onRightClick}>
        <img src={ArrowNext} alt={'arrow-next'} />
      </S.BtnWr>
    </S.ArrowsBlock>
  );
};
