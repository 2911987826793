import React from 'react';
import {useTranslation} from 'react-i18next';
import {ModalWrapper} from '../modalWrapper';
import * as S from './styled';
import {getPreviewDataUrl} from '../../helpers/imageHelper';

const rows = 3;
const columns = 3;

export const PatternPreview = props => {
  const {
    popupRef,
    onClose,
    settings,
    scale,
    canvasPreviewRef,
    previewWithRulerRef,
  } = props;
  const {t} = useTranslation(['PatternPreview']);
  const buttonsContainer = null;
  const previewBlobUrl = getPreviewDataUrl(
    canvasPreviewRef?.current,
    previewWithRulerRef?.current,
    settings,
    scale,
    2,
  );

  const imgContainer = <S.ImgSide src={previewBlobUrl} alt="Preview" />;

  const imagesContainer = Array(rows)
    .fill(0)
    .map((_, i) => (
      <S.Row key={i}>
        {Array(columns)
          .fill(0)
          .map((_, j) => (
            <S.Column key={j}>{imgContainer}</S.Column>
          ))}
      </S.Row>
    ));

  return (
    <ModalWrapper
      popupRef={popupRef}
      onCancelClick={() => onClose()}
      title={t('Pattern Preview')}
      withCross
      isScrolled={false}
      buttonsContainer={buttonsContainer}>
      <S.PreviewRoot>{imagesContainer}</S.PreviewRoot>
    </ModalWrapper>
  );
};
