import styled from 'styled-components';

export const PreviewRoot = styled.div`
  min-width: 340px;
  padding: 20px;
`;

export const ImgSide = styled.img`
  width: 100px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;
