import mirror1Img from '../assets/icons/mirror/Mirror1.svg';
import mirror2Img from '../assets/icons/mirror/Mirror2.svg';

export const mirrorVariants = {
  horizontal: 'horizontal',
  vertical: 'vertical',
}

export const mirrorMenu = [
  {
    id: mirrorVariants.horizontal,
    icon: mirror1Img,
  },
  {
    id: mirrorVariants.vertical,
    icon: mirror2Img,
  },
];
