import styled from 'styled-components';

export const List = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Item = styled.div`
  width: 46px;
  height: 46px;
  cursor: pointer;
  margin-right: ${p => p?.$marginsInside}px;
  border: ${p => (p?.$isSelected ? '3px' : 0)} solid ${p => p.theme.primaryColor};
`;
