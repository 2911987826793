import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import {
  en_US,
  uk_UA,
  nl_NL,
  nl_BE,
  fr_FR,
  de_DE,
  es_ES,
  it_IT,
} from '../locales';

const defaultLocale = 'en_US';

const languageDetector = {
  type: 'languageDetector',
  async: true,
  detect: cb => cb('en_US'),
  init: () => {},
  cacheUserLanguage: () => {},
};

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en_US', // the translations
    fallbackNS: 'translation',
    lng: 'en_US',
    resources: {
      en_US: {translation: en_US},
      uk_UA: {translation: uk_UA},
      //      nl_NL,
      nl_NL: {translation: nl_NL},
      nl_BE: {translation: nl_BE},
      fr_FR: {translation: fr_FR},
      de_DE: {translation: de_DE},
      es_ES: {translation: es_ES},
      it_IT: {translation: it_IT},
      ns: ['translation'],
    },
  });
// export default i18n;

export const setBrowserLanguage = i18n => {
  const languages = navigator.languages;
  const supportedLanguages = [
    'en_US',
    'nl_NL',
    'de_DE',
    'fr_FR',
    'es_ES',
    'it_IT',
    'uk_UA',
  ];

  const supportedList = languages.filter(i => {
    return supportedLanguages.find(
      j => i.substring(0, 2) === j.substring(0, 2),
    );
  });
  const lang = supportedLanguages.filter(i =>
    i.includes(supportedList?.[0].replace('-', '_')),
  );
  i18n.changeLanguage(lang?.[0] || defaultLocale);
};
