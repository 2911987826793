export const handleError = e => console.log(e);

export const getErrorMessage = errors => {
  let result;
  if (errors) {
    const values = Object.values(errors);
    result = values.map(i => String(i));
  }
  return result;
};

export const showErrorNotification = (value, showErrorMessage) => {
  const {response} = value || {};
  const {errors, error} = response?.data || {};

  if (errors && showErrorMessage) {
    showErrorMessage({
      message: getErrorMessage(errors),
      isError: true,
    });
  }
  if (error && showErrorMessage) {
    showErrorMessage({
      message: String(error),
      isError: true,
    });
  }
};
