import Api from '../ApiV2';

export const getProduct = async (id: any): Promise<any> => {
  const response = await Api.shop.integration.products.get(id);
  return response?.data;
};

export const getProductVariant = async (
  config: any,
  pid: any,
  vid: any,
): Promise<any> => {
  const response = await Api.shop.integration.products.variants.get(
    {pid, id: vid},
    null,
    config,
  );
  return response?.data;
};

export const updateCustomization = async (
  config: any,
  data: any,
): Promise<any> => {
  const response = await Api.shop.integration.productCustomizations.put(
    {},
    data,
    config,
  );
  return response?.data;
};

export const createCustomization = updateCustomization;

export const uploadTemplateImage = async (
  cid: number,
  data: any,
  onError: Function,
) => {
  try {
    const response =
      await Api.shop.integration.productCustomizations.uploads.post(
        {cid},
        data,
      );
    return await response?.data;
  } catch (e) {
    if (onError) {
      onError(e);
    }
  }
};
