import styled from 'styled-components';

export const WrapperInput = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 40px;
  align-items: center;
`;

export const LabelBlock = styled.h4`
  width: ${props => props.theme.labelWidth};
  min-width: ${props => props.theme.labelWidth};
  font-size: ${props => props.theme.normal};
  font-weight: bold;
  color: #000;
  font-family: ${props => props.theme.mainFont};
`;

export const InputBlock = styled.div`
  display: grid;
  flex-direction: row;
  height: 100%;
  width: 100%;
  input {
    grid-row: 1;
    grid-column: 1;
    padding-left: 20px;
    padding-right: 50px;
    height: 100%;
    border: 1px solid ${props => props.theme.borderLightColor};
    border-radius: 3px;
    //width: 70px;
    width: 100%;
    font-size: ${props => props.theme.normal};
    font-family: ${props => props.theme.mainFont};
    font-weight: 500;
  }
`;

export const TypeBlock = styled.div`
  height: 36px;
  @-moz-document url-prefix() {
    height: 40px;
    margin-right: 0;
    border: 1px solid ${props => props.theme.borderLightColor};
  }
  z-index: 1;
  margin-right: 2px;
  width: 46px;
  grid-row: 1;
  grid-column: 1;
  border-radius: 3px;
  background-color: #fbfbfb;
  color: #a8a8a8;
  //border: 1px solid ${props => props.theme.borderLightColor};
  border-left: 1px solid ${props => props.theme.borderLightColor};
  justify-self: flex-end;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom-left-radius: unset;
  border-top-left-radius: unset;
  font-size: ${props => props.theme.small};
  font-family: ${props => props.theme.mainFont};
`;
