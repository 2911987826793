import React from 'react';
import {Blur} from 'konva/lib/filters/Blur';
import {Sepia} from 'konva/lib/filters/Sepia';
import {Invert} from 'konva/lib/filters/Invert';
import {Grayscale} from 'konva/lib/filters/Grayscale';
import {Text} from 'konva/lib/shapes/Text';
import {uploadTemplateImage} from '../api/helpers/integration';
//import {checkoutCartUploads} from '../api/helpers/checkout';

export const objectTypes = {
  background: 'Background',
  text: 'Text',
  image: 'Image',
  clipart: 'Clipart',
  group: 'Group',
};

export const classNames = {
  text: 'Text',
  image: 'Image',
  rect: 'Rect',
  circle: 'Circle',
  group: 'Group',
};

export const filterList = [Blur, Sepia, Invert, Grayscale];

export const filtersDetailsList = [
  {name: 'Blur', value: Blur},
  {name: 'Sepia', value: Sepia},
  {name: 'Invert', value: Invert},
  {name: 'Grayscale', value: Grayscale},
];

//const timeStamp = new Date().getTime();
const imageSuffixKey = `?getthumbnail=1200`;
//const imageSuffixKey = ``;

export const getImageUrlWithSuffix = url => {
  return `${url}${imageSuffixKey}`;
};

export const getImageUrlWithoutSuffix = url => {
  if (url) {
    const timestampIndex = url.indexOf('?');
    if (timestampIndex !== -1) {
      return url.substring(0, timestampIndex);
    }
    return url;
  }
};

export const getWindowImage = url => {
  const image = new window.Image();
  if (url) {
    const imageSuffixIndex = url.indexOf('?');
    image.src =
      imageSuffixIndex === -1 && url.startsWith('http')
        ? getImageUrlWithSuffix(url)
        : url;
  }
  image.crossOrigin = 'Anonymous';
  return image;
};

const isFirefox = typeof InstallTrigger !== 'undefined';

export const parseSpecialParams = params => {
  if (params?.imageSrc) {
    const image = getWindowImage(params.imageSrc);
    params.image = image;
  }
  if (params?.fillPatternImageSrc) {
    const image = getWindowImage(params.fillPatternImageSrc);
    params.fillPatternImage = image;
  }
  if (params?.filtersData) {
    params.filters = params.filtersData.map(
      v => filtersDetailsList.find(i => i?.name === v)?.value,
    );
  }
  if (params?.predefinedImages?.length) {
    params.predefinedImages = params?.predefinedImages.map(i => ({
      ...i,
      image: getWindowImage(i?.imageSrc),
    }));
  }
  if (
    isFirefox &&
    params?.fontStyle === 'bold' &&
    params.fontFamily &&
    [
      'Amatic SC',
      'Amatic SC Regular',
      'Amatic SC Bold',
      'Davish',
      'Northwell',
      'Olive Display',
      'Avocado Sans Bold',
      'Futura PT Light Italic',
    ].includes(params.fontFamily)
  ) {
    params.fontStyle = 'normal';
  }
};

export const getUploadedImageUrl = async (file, cid, onError) => {
  const formData = new FormData();
  formData.append('name', file?.name);
  formData.append('file', file);
  const response = await uploadTemplateImage(cid, formData, onError);
  return response?.data?.file?.url;
};

export const getParamsWithSpecialKeys = inputParams => {
  const params = {...inputParams};
  if (params?.image?.src) {
    params.imageSrc = getImageUrlWithoutSuffix(params?.image?.src);
    delete params['image'];
  }
  if (params?.fillPatternImage?.src) {
    params.fillPatternImageSrc = getImageUrlWithoutSuffix(
      params?.fillPatternImage?.src,
    );
    delete params['fillPatternImage'];
  }
  if (params?.predefinedImages?.length) {
    params.predefinedImages = params.predefinedImages.map(i => ({
      ...i,
      imageSrc: getImageUrlWithoutSuffix(i?.image?.src),
    }));
  }
  if (params?.filters) {
    params.filtersData = params?.filters.map(
      v => filtersDetailsList.find(i => i?.value === v)?.name,
    );
  }
  return params;
};

export const syncParams = (ref, params) => {
  const keys = Object.keys(params);
  if (ref) {
    keys.forEach(key => {
      if (ref?.getAttr && params?.[key] !== ref?.getAttr(key)) {
        ref.setAttr(key, params[key]);
      }
    });
  }
};

export const cacheObject = (ref, o) => {
  if (ref?.attrs?.image?.src) {
    ref.attrs.image.onload = () => {
      if (ref.attrs.filters?.length) {
        ref.cache();
      } else {
        o.key = `id=${o?.id},Math=${Math.random()}`;
      }
    };
  } else if (ref?.attrs?.fillPatternImage?.src) {
    ref.attrs.fillPatternImage.onload = () => {
      let repeat = ref?.attrs?.fillPatternRepeat;
      ref.setAttr('fillPatternRepeat', '');
      ref.setAttr('fillPatternRepeat', repeat);
      if (ref.attrs.filters?.length) {
        ref.cache();
      } else {
        o.key = `id=${o?.id},Math=${Math.random()}`;
      }
    };
  } else {
    if (ref.attrs.filters?.length) {
      ref.cache();
    } else {
      o.key = `id=${o?.id},Math=${Math.random()}`;
    }
  }
};

export const fontList = [
  'Arial',
  'Open Sans',
  'Amatic SC',
  'Amatic SC Regular',
  'Amatic SC Bold',
  'Davish',
  'Northwell',
  'Olive Display',
  'Avocado Sans Bold',
  'Futura PT Light Italic',
  'Kalam Bold',
  'Montana',
  'Butler',
  'Amithen',
  'Gabriola',
  'Remachine Script Personal Use',
  'Roboto Slab',
  'Original Surfer Regular',
  'Sweet Someday',
  'Cowboys 2.0',
];

export const loadAllFonts = () => {
  if (Text) {
    const text = new Text();
    fontList.forEach(i => {
      text.fontFamily(i);
    });
  }
};

export const canvasToJSON = (settings, objects = [], groups, fonts) => {
  // eslint-disable-next-line no-undef
  const objectsToSave = objects.map(i => ({
    id: i?.id,
    name: i?.name,
    type: i?.type,
    className: i?.className,
    params: getParamsWithSpecialKeys(i?.params),
    personalization: i?.personalization,
  }));
  return {
    settings,
    background: objectsToSave.find(o => o.type === objectTypes.background),
    objects: objectsToSave.filter(o => o.type !== objectTypes.background),
    groups,
    fonts,
  };
};

export const restoreLayersFromApi = template => {
  const templateLayers = template?.layers || [];
  let layersData = [];
  templateLayers.forEach((layer, index) => {
    const {settings = {}, background, objects = []} = layer?.data || {};
    settings.width = Number(settings?.width);
    settings.height = Number(settings?.height);
    settings.name = template?.name;
    settings.isCustomizable = template?.is_customizable;
    const objectsList = [...(background ? [background] : []), ...objects];
    //checkAndFixIds(objectsList);
    const objectsWithParsedParams = objectsList.map((i, index) => {
      parseSpecialParams(i?.params);
      const id = index + 1;
      i.id = i?.id || id;
      i.key = `id=${id},Math=${Math.random()}`;
      if (i?.data) {
        [
          ...(i?.data?.background ? [i.data.background] : []),
          ...i.data.objects,
        ].map((groupItem, index) => {
          parseSpecialParams(groupItem?.params);
          groupItem.id = groupItem?.id || index + 1;
          groupItem.key = `id=${groupItem?.id},Math=${Math.random()}`;
          return groupItem;
        });
      }
      return i;
    });
    layersData.push({
      layer_name: layer?.name,
      layer_id: layer?.id,
      layer_order: layer?.order,
      ...layer?.data,
      objects: objectsWithParsedParams,
      index,
    });
  });
  return layersData;
};

export const restoreCanvasFromApi = (template, order = 1) => {
  const layers = template?.layers || [];
  const layer = layers.find(i => i?.order === (order || 1));
  const {
    settings,
    background,
    objects: objectsList,
    groups,
    fonts,
  } = layer.data;
  const objects = [...(background ? [background] : []), ...objectsList].map(
    (i, index) => {
      parseSpecialParams(i?.params);
      const id = index + 1;
      i.id = i?.id || id;
      i.key = `id=${id},Math=${Math.random()}`;
      return i;
    },
  );
  return {settings, objects, groups, fonts};
};

export const restoreCanvasFromDetailsJson = canvasJson => {
  const {
    settings,
    background,
    objects: objectsList,
    groups,
    fonts,
  } = canvasJson || {};
  const objects = [...(background ? [background] : []), ...objectsList].map(
    (i, index) => {
      parseSpecialParams(i?.params);
      const id = i?.id || index + 1;
      i.id = id;
      return i;
    },
  );
  return {settings, objects, groups, fonts};
};

export const dataURLtoBlob = async dataURL => {
  const res = await fetch(dataURL);
  return res.blob();
};

export const getFileByDataUrl = async (dataUrl, name = 'file.png') => {
  const blob = await dataURLtoBlob(dataUrl);
  return new File([blob], name);
};

export const generateHashCode = s =>
  s.split('').reduce((a, b) => ((a << 5) - a + b.charCodeAt(0)) | 0, 0);

export function updateResizableText(params) {
  if (!params.initialFontSize) {
    params.initialFontSize = params.fontSize;
  }
  let textObj = new Text({...params, height: undefined});
  if (
    textObj.size().height < params.height &&
    textObj.fontSize() < params.initialFontSize
  ) {
    while (textObj.size().height < params.height) {
      const fontSize = textObj.fontSize();
      textObj.fontSize(fontSize + 1);
    }
  }
  if (textObj.size().height > params.height) {
    while (textObj.size().height > params.height) {
      const fontSize = textObj.fontSize();
      textObj.fontSize(fontSize - 1);
    }
  }
  params.fontSize = textObj.fontSize();
}

export const emoji =
  /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g;

export const loadFontsFromTemplate = async fonts => {
  for (const i of fonts || []) {
    const customFont = new FontFace(i?.name, `url(${i?.url})`);
    await customFont.load();
    document.fonts.add(customFont);
  }
};

export const loadFontsFromLayers = async layers => {
  await Promise.all(
    layers.map(async layer => {
      const fonts = layer?.fonts;
      for (const i of fonts || []) {
        const customFont = new FontFace(i?.name, `url(${i?.url})`);
        await customFont.load();
        document.fonts.add(customFont);
      }
    })
  )
};

export const getImageDimension = (url, onResult) => {
  const image = new window.Image();
  image.src = url;
  image.onload = function () {
    onResult({width: this.width, height: this.height});
  };
};
