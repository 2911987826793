export const getSearchCounts = (string, word) => {
  return string.split(word).length - 1;
};

export function getTextareaNumberOfLines(textarea) {
  let previous_height = textarea.style.height;
  let lines;
  textarea.style.height = 0;
  lines = parseInt(
    textarea.scrollHeight / parseInt(getComputedStyle(textarea).lineHeight, 10),
    10,
  );
  textarea.style.height = previous_height;
  return lines;
}

export const fixInputsWithNumbers = () => {
  document.addEventListener('wheel', function (event) {
    if (
      // @ts-ignore
      document.activeElement.type === 'number' &&
      document.elementFromPoint(event.x, event.y) == document.activeElement
    ) {
      // @ts-ignore
      document.activeElement.blur();
    }
  });
};
