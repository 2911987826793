import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExclamationCircle} from '@fortawesome/free-solid-svg-icons';
import * as S from './styled';

export const NotificationPopup = ({message, notFixed, isError}) => {
  const styles = {
    position: !notFixed ? 'fixed' : 'relative',
    top: !notFixed ? '175px' : 'auto',
    right: !notFixed ? '0' : 'auto',
    zIndex: 110,
  };

  const iconStyles = {
    color: !isError ? '#111F6A' : '#e30b13',
  };

  return (
    <S.Notification style={styles}>
      <FontAwesomeIcon icon={faExclamationCircle} fontSize={14} style={iconStyles} />
      {message}
    </S.Notification>
  );
};
