import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import Select, {components} from 'react-select';
import * as S from './styled';

const primaryColor = '#3e68f6';

const Option = props => (
  <div>
    <components.Option {...props}>
      <input
        type="checkbox"
        className="checkbox-style"
        checked={props.isSelected}
        value={props.value}
        onChange={() => null}
        //label={props.label}
      />
    </components.Option>
  </div>
);

const MenuList = ({maxHeight, children}) => (
  <div className="menu-list" style={{overflowY: 'auto', maxHeight}}>
    {children}
  </div>
);

export const Dropdown = ({
  data,
  onChange,
  value,
  placeholder,
  width,
  height,
  showAllLabel,
  closeMenuOnSelect,
  isMulti,
  defaultValue = null,
  hideSelectedOptions = false,
  isSearchable = true,
  backspaceRemovesValue = false,
  controlShouldRenderValue = true,
  maxMenuHeight,
  containerStyle = {},
  disabled = false,
  getOptionLabel = option => option?.name,
  getOptionValue = option => option?.id,
  onInputChange,
  //onInputChangeWithDelay,
  inputValue,
  showItemsSelected,
  onKeyDown,
  fontSize,
  withoutBorder,
  inputId,
  menuPlacement = 'bottom',
  filterOption,
  label,
}) => {
  //const delayRef = useRef(null);
  let isStringArray = false;
  if (data?.length && data?.[0] === String(data?.[0])) {
    data = data.map(i => ({label: getOptionLabel(i), value: i}));
    value =
      value && Array.isArray(value)
        ? value.map(i => ({label: i, value: i}))
        : value
        ? {label: value, value: value}
        : value;
    getOptionLabel = v => v?.label;
    getOptionValue = v => v?.value;
    isStringArray = true;
  }
  const showAllItem = {name: showAllLabel, id: null};
  const currValue = useMemo(
    () => value || (showAllLabel ? showAllItem : null),
    [value],
  );
  const selected = useMemo(() => currValue, [currValue]);

  const handleInputChange = v => {
    if (onInputChange) {
      onInputChange(v);
    }
  };

  const MultiValue = props =>
    getOptionValue(props.data) ? (
      <components.MultiValue {...props}>
        <span>{getOptionLabel(props.data)}</span>
      </components.MultiValue>
    ) : null;

  const onSelect = value => {
    //setSelected(value);
    onChange(
      isStringArray
        ? value?.length
          ? value.map(i => i?.value)
          : value?.value
        : value,
    );
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      fontSize: fontSize || '16px',
      lineHeight: 1.5,
      color: state.isFocused ? primaryColor : '#1E1E1E',
      fontWeight: 600,
      backgroundColor: state.isFocused ? '#F7F7F7' : '#fff',
      cursor: 'pointer',
      padding: 5,
    }),
    control: provided => ({
      ...provided,
      cursor: 'pointer',
      minHeight: height || 38,
      height,
      borderColor: '#e6e6e6',
      backgroundColor: withoutBorder && 'transparent',
      border: withoutBorder && 'none',
      boxShadow: withoutBorder && 'none',
      fontSize: fontSize || '16px',
      '&:hover': {
        borderColor: withoutBorder && 'transparent',
      },
    }),
    singleValue: provided => ({
      ...provided,
      color: '#6f6f6f',
    }),
    indicatorSeparator: provided => ({
      ...provided,
      display: 'none',
      marginBottom: 0,
      marginTop: 0,
    }),
    dropdownIndicator: provided => ({
      ...provided,
      padding: height ? 5 : 10,
    }),
    menu: provided => ({
      ...provided,
      marginTop: 0,
      zIndex: 2,
    }),
    multiValue: provided => ({
      ...provided,
      backgroundColor: '#dee8ff',
      borderRadius: 20,
      //border: `1px solid ${primaryColor}`,
      color: primaryColor,
      fontSize: fontSize || '16px',
    }),
    multiValueLabel: provided => ({
      ...provided,
      color: 'black',
    }),
    multiValueRemove: provided => ({
      ...provided,
      borderRadius: 13,
      fontSize: fontSize || '16px',
    }),
    menuList: provided => ({
      ...provided,
      fontSize: fontSize || '16px',
    }),
  };
  const stylesLabel = {
    fontSize: '14px',
    fontFamily: 'Roboto',
    fontWeight: '400',
    margin: '0 0 5px 0',
    color: '#000',
  };

  const options = showAllLabel ? [showAllItem, ...data] : data;

  return (
    <S.WrapperInput>
      {label && <S.LabelBlock>{label}</S.LabelBlock>}
      <div
        className="rounded-select"
        style={{...containerStyle, width: width || '100%'}}>
        <Select
          value={selected}
          {...(inputId ? {inputId: inputId} : {})}
          inputValue={inputValue === null ? undefined : inputValue}
          defaultValue={defaultValue}
          options={options}
          getOptionLabel={getOptionLabel}
          getOptionValue={getOptionValue}
          menuPlacement={menuPlacement}
          components={isMulti ? {Option, MultiValue, MenuList} : {MenuList}}
          placeholder={
            showItemsSelected
              ? `${selected.length} items selected`
              : selected || placeholder || 'Select ...'
          }
          openMenuOnFocus={false}
          cacheOptions
          styles={customStyles}
          onChange={onSelect}
          onInputChange={handleInputChange}
          closeMenuOnSelect={closeMenuOnSelect}
          isMulti={isMulti}
          hideSelectedOptions={hideSelectedOptions}
          backspaceRemovesValue={backspaceRemovesValue}
          controlShouldRenderValue={controlShouldRenderValue}
          maxMenuHeight={maxMenuHeight}
          isDisabled={disabled}
          search={isSearchable}
          autoComplete={isSearchable ? 'on' : 'off'}
          onKeyDown={onKeyDown}
          isSearchable={isSearchable}
          filterOption={filterOption}
        />
      </div>
    </S.WrapperInput>
  );
};

Dropdown.propTypes = {
  inputId: PropTypes.string || PropTypes.number,
  data: PropTypes.array,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.object,
    PropTypes.string,
  ]),
  placeholder: PropTypes.string,
  width: PropTypes.number,
  showAllLabel: PropTypes.string,
  closeMenuOnSelect: PropTypes.bool,
  isMulti: PropTypes.bool,
  defaultValue: PropTypes.PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.object,
  ]),
  hideSelectedOptions: PropTypes.bool,
  isSearchable: PropTypes.bool,
  backspaceRemovesValue: PropTypes.bool,
  controlShouldRenderValue: PropTypes.bool,
  maxMenuHeight: PropTypes.number,
  containerStyle: PropTypes.object,
  disabled: PropTypes.bool,
};
