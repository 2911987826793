import styled from 'styled-components';

export const CanvasWrapper = styled.div`
  display: flex;
  min-width: 1px;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const RootContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: ${props => props.theme.spaceNormal};
  width: 100%;
  min-height: 100%;
`;

