import styled from 'styled-components';

export const Counter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  height: 40px;
  ${props => props.theme.screenSize.xl} {
    height: 30px;
  }
  border: 1px solid #d1d1d1;
  border-radius: 3px;
  width: fit-content;
`;

export const CounterBtn = styled.span`
  width: 40px;
  ${props => props.theme.screenSize.xl} {
    width: 30px;
  }
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
  opacity: ${props => (props.$isdisable ? '0.5' : '1')};
  cursor: ${props => (props.$isdisable ? 'not-allowed' : 'pointer')};
`;

export const CounterQuantity = styled.div`
  width: 64px;
  ${props => props.theme.screenSize.xl} {
    width: 48px;
  }
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 0;
  border-bottom: 0;
  border-left: 1px solid #d1d1d1;
  border-right: 1px solid #d1d1d1;
  position: relative;
`;

export const CounterQuantityInput = styled.input`
  border-top: 0;
  border-bottom: 0;
  border-left: 1px solid #d1d1d1;
  border-right: 1px solid #d1d1d1;
  width: inherit;
  height: 100%;
  text-align: center;
  font-size: 15px;
`;
