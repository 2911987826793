import React, {useState} from 'react';
import {NotificationPopup} from '../components';

export function withNotification(WrappedComponent) {
  return props => {
    const [notificationData, setNotificationData] = useState({});
    const [isShowing, setIsShowing] = useState(false);

    const showNotification = data => {
      setNotificationData({...data, show: true});
      setIsShowing(true);
      setTimeout(() => {
        setIsShowing(false);
      }, 3000);
    };

    return (
      <>
        {isShowing && (
          <NotificationPopup
            message={notificationData.message}
            isError={notificationData.isError}
            show={notificationData.show}
          />
        )}
        <WrappedComponent showNotification={showNotification} {...props} />
      </>
    );
  };
}
