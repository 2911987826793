export const initImageWithCache = (o, ref) => {
  const image = o?.params?.image || o?.params?.fillPatternImage;
  if (image?.src) {
    image.onload = () => {
      let repeat = ref?.attrs?.fillPatternRepeat;
      ref.setAttr('fillPatternRepeat', '');
      ref.setAttr('fillPatternRepeat', repeat);
      ref.cache();
    };
  }
};

export const getPreviewDataUrl = (
  ref,
  prevWithRulerRef,
  settings,
  scale,
  pixelRatio,
) => {
  const x = prevWithRulerRef.getPosition()?.x + ref.getPosition()?.x * scale;
  const y = prevWithRulerRef.getPosition()?.y + ref.getPosition()?.y * scale;
  return ref.toDataURL({
    pixelRatio: pixelRatio,
    x,
    y,
    width: settings?.width * scale,
    height: settings?.height * scale,
  });
};
