import React from 'react';
import PropTypes from 'prop-types';
import {TailSpin} from 'react-loader-spinner';

export const Loader = ({
  width = 100,
  height = 100,
                       // eslint-disable-next-line no-undef
  color = process.env.REACT_APP_THEME_PRIMARY_COLOR || '#307feb',
  type = 'Puff',
  style,
}) => {
  const containerStyle = {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(229, 229, 229, 0.5)',
    ...style,
  };
  return (
    <div style={containerStyle}>
      <div
        style={{
          width: 100,
          height: 100,
          zIndex: 100,
        }}>
        <TailSpin type={type} color={color} height={height} width={width} />
      </div>
    </div>
  );
};

Loader.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  type: PropTypes.string,
  style: PropTypes.any,
};

