import {Text} from 'konva/lib/shapes/Text';

export function getFontSizeForObjectWithFixedSize(params) {
  const initialFontSize = params.fontSize;
  const maxRows = params?.maxRowsForWrap;
  let textObj = new Text({...params, height: undefined});
  if (
    textObj.size().height < params.height &&
    textObj.fontSize() < initialFontSize
  ) {
    while (textObj.size().height < params.height) {
      const fontSize = textObj.fontSize();
      textObj.fontSize(fontSize + 1);
    }
  }
  if (textObj.size().height > params.height) {
    while (textObj.size().height > params.height) {
      const fontSize = textObj.fontSize();
      textObj.fontSize(fontSize - 1);
    }
  }
  if (maxRows && textObj.size().height >= textObj.fontSize() * (maxRows + 1)) {
    while (
      textObj.size().height >= textObj.fontSize() * (maxRows + 1) &&
      textObj.fontSize() >= 1
    ) {
      const fontSize = textObj.fontSize();
      textObj.fontSize(fontSize - 1);
    }
  }
  return textObj.fontSize();
}

export const getFixedParams = params => {
  let {text, textTransform} = params;
  if (text && textTransform === 'uppercase') {
    text = text.toUpperCase();
  }
  if (text && textTransform === 'lowercase') {
    text = text.toLowerCase();
  }
  let result = {
    ...params,
    ...(text ? {text} : {}),
    ...(text && params?.height
      ? {
          fontSize: getFontSizeForObjectWithFixedSize({
            ...params,
            text,
          }),
        }
      : {}),
  };
  return result;
};

export const getGroupDescription = (groups, main) => {
  let selectedDescription = null;
  if (groups?.length) {
    if (main) {
      selectedDescription =
        groups.find(item => item?.parent_group?.id)?.description ||
        groups.find(item => !item?.parent_group?.id)?.description;
    } else {
      selectedDescription =
        groups.find(item => item?.parent_group?.id)?.description2 ||
        groups.find(item => !item?.parent_group?.id)?.description2;
    }
  }
  return selectedDescription;
};
